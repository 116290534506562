<template>
  <b-overlay :show="loadingDatesLock" variant="white">
    <b-row class="mb-2">
      <!-- START DATE -->
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Data d'inici</h6>
        <b-card-text>
          {{ datesLockStartDate }}
        </b-card-text>
      </b-col>

      <!-- END DATE -->
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Data de fi</h6>
        <b-card-text>
          {{ datesLockEndDate }}
        </b-card-text>
      </b-col>

      <!-- DURATION -->
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Durada</h6>
        <b-card-text>
          {{ datesLockDays }}
        </b-card-text>
      </b-col>

      <!-- GUESTS -->
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Tipus</h6>
        <b-card-text>
          {{ datesLockType }}
        </b-card-text>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="$emit('close')">
          Tanca
        </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="danger" @click="askDeleteDatesLock">
          Elimina
        </b-button>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { BOverlay, BRow, BCol, BCardText, BButton } from "bootstrap-vue";
import { getNightsText, notifyError } from "@/utils/methods";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  name: "DatesLockDetails",
  components: {
    BOverlay,
    BRow,
    BCol,
    BCardText,
    BButton,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  computed: {
    loadingDatesLock() {
      return this.$store.getters["datesLock/loading"];
    },
    datesLock() {
      return this.$store.getters["datesLock/datesLock"];
    },
    days() {
      return this.$store.getters["datesLock/days"];
    },
    datesLockStartDate() {
      return (
        formatDateStringToDate(this.datesLock?.startDate) ||
        this.$t("No definit")
      );
    },
    datesLockEndDate() {
      return (
        formatDateStringToDate(this.datesLock?.endDate) || this.$t("No definit")
      );
    },
    datesLockDays() {
      return getNightsText(this.days) || this.$t("No definit");
    },
    datesLockType() {
      if (!this.datesLock?.scope) return null;
      switch (this.datesLock.scope.toUpperCase()) {
        case "BOOKING":
          return "Reserva externa";
        case "CLOSED":
          return "Calendari tancat";
        case "PERSONAL":
          return "Ús personal";
        default:
          return this.datesLock.scope;
      }
    },
  },
  created() {
    this.fetchDatesLock();
  },
  methods: {
    fetchDatesLock() {
      if (!this.uuid) return;
      this.$store.dispatch("datesLock/fetchDatesLock", this.uuid).catch(() => {
        notifyError(
          this.$t("errors.fetchDatesLock.title"),
          this.$t("errors.fetchDatesLock.description")
        );
      });
    },
    askDeleteDatesLock() {
      this.$swal({
        title: "Eliminar bloqueig",
        text: `Estàs a punt d'eliminar el bloqueig de ${this.datesLockStartDate} a ${this.datesLockEndDate}, estàs segur/a?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteDatesLock();
        }
      });
    },
    deleteDatesLock() {
      this.$store.dispatch("app/setLoading", true);
      const promises = [];

      // Dates lock
      promises.push(
        this.$store.dispatch("datesLock/removeDatesLock", this.uuid)
      );

      // Availabilities
      promises.push(
        this.$store.dispatch(
          "accommodation/updateAccommodationAvailabilities",
          {
            accommodation: this.datesLock.accommodation["@id"],
            startDate: this.datesLock.startDate,
            endDate: this.datesLock.endDate,
            available: true,
          }
        )
      );

      Promise.all(promises)
        .then(() => this.$emit("dates-lock-deleted"))
        .catch(() => this.$emit("delete-dates-lock-error"))
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
