<template>
  <b-overlay :show="loadingBooking" variant="white">
    <b-row class="mb-2">
      <!-- LOCALIZATOR -->
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Localitzador</h6>
        <b-card-text class="cursor-pointer" @click="openBookingInNewTab">
          {{ bookingLocalizator }}
          <feather-icon icon="ExternalLinkIcon" class="ml-25" />
        </b-card-text>
      </b-col>

      <!-- PVP -->
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">PVP</h6>
        <b-card-text>
          {{ bookingPvpPrice }}
        </b-card-text>
      </b-col>

      <!-- SOURCE -->
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Portal</h6>
        <b-card-text>
          {{ bookingSource }}
        </b-card-text>
      </b-col>

      <!-- SOURCE LOCALIZATOR -->
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Referència</h6>
        <b-card-text>
          {{ bookingSourceLocalizator }}
        </b-card-text>
      </b-col>

      <!-- CHECKIN -->
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Entrada</h6>
        <b-card-text>
          {{ bookingCheckin }}
        </b-card-text>
      </b-col>

      <!-- CHECKOUT -->
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Sortida</h6>
        <b-card-text>
          {{ bookingCheckout }}
        </b-card-text>
      </b-col>

      <!-- NIGHTS -->
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Vespres</h6>
        <b-card-text>
          {{ bookingNights }}
        </b-card-text>
      </b-col>

      <!-- GUESTS -->
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Hostes</h6>
        <b-card-text>
          {{ bookingGuests }}
        </b-card-text>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="$emit('close')">
          Tanca
        </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="openBookingInNewTab">
          Mostrar
        </b-button>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { BOverlay, BRow, BCol, BCardText, BButton } from "bootstrap-vue";
import {
  getGuestsText,
  getNightsText,
  getOtaName,
  notifyError,
} from "@/utils/methods";
import { formatCurrency, formatDateStringToDate } from "@/utils/formatters";

export default {
  name: "BookingDetails",
  components: {
    BOverlay,
    BRow,
    BCol,
    BCardText,
    BButton,
  },
  props: {
    localizator: {
      type: String,
      required: true,
    },
  },
  computed: {
    loadingBooking() {
      return this.$store.getters["booking/loading"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    bookingLocalizator() {
      return this.booking?.localizator || this.$t("No definit");
    },
    bookingPvpPrice() {
      return formatCurrency(this.booking?.pvpPrice) || this.$t("No definit");
    },
    bookingSource() {
      return getOtaName(this.booking?.source) || this.$t("No definit");
    },
    bookingSourceLocalizator() {
      return this.booking?.sourceLocalizator || this.$t("No definit");
    },
    bookingCheckin() {
      return (
        formatDateStringToDate(this.booking?.checkin) || this.$t("No definit")
      );
    },
    bookingCheckout() {
      return (
        formatDateStringToDate(this.booking?.checkout) || this.$t("No definit")
      );
    },
    bookingNights() {
      return getNightsText(this.booking?.nights) || this.$t("No definit");
    },
    bookingGuests() {
      return (
        getGuestsText({
          adults: this.booking?.adults,
          children: this.booking?.children,
          babies: this.booking?.babies,
        }) || this.$t("No definit")
      );
    },
  },
  created() {
    this.fetchBooking();
  },
  methods: {
    fetchBooking() {
      if (!this.localizator) return;
      this.$store
        .dispatch("booking/fetchBookingByLocalizator", this.localizator)
        .catch(() => {
          notifyError(
            this.$t("errors.fetchBooking.title"),
            this.$t("errors.fetchBooking.description")
          );
        });
    },
    openBookingInNewTab() {
      const routeData = this.$router.resolve({
        name: "foravila-booking-view",
        params: {
          localizator: this.localizator,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
