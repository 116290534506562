<template>
  <b-overlay
    id="bookings-calendar"
    :show="loadingAccommodations"
    rounded="sm"
    variant="white"
    :opacity="1"
  >
    <b-card>
      <!-- NEW BOOKING BUTTON AND SEARCH -->
      <b-row class="mb-2 d-flex justify-content-between">
        <b-col cols="3" md="auto" class="mb-1 mb-md-0">
          <b-button
            variant="primary"
            block
            class="d-flex justify-content-center"
            :disabled="!selectedAccommodationUuid"
            @click.prevent="onAddButtonClicked"
          >
            <feather-icon icon="PlusIcon" class="d-block d-md-none" />
            <span class="d-none d-md-inline">{{ $t("Nou bloqueig") }}</span>
          </b-button>
        </b-col>
        <b-col cols="9" md="auto" class="mb-1 mb-md-0">
          <v-select
            v-model="selectedAccommodationUuid"
            class="accommodation-select"
            placeholder="Selecciona un allotjament"
            :options="activeAccommodationOptions"
            :reduce="(option) => option.value"
          />
        </b-col>
      </b-row>

      <!-- CALENDAR -->
      <b-row>
        <b-col>
          <calendar
            render-style="background"
            language="ca"
            :enable-range-selection="true"
            :year="selectedYear"
            :data-source="unavailableDates"
            :disabled-week-days="
              selectedAccommodationUuid ? [] : [0, 1, 2, 3, 4, 5, 6]
            "
            @select-range="onDatesSelected"
            @year-changed="onYearChanged"
          />
        </b-col>
      </b-row>

      <!-- LEGEND -->
      <b-row>
        <b-col cols="12">
          <div
            id="calendar-legend"
            class="mt-2 p-1 d-flex flex-wrap justify-content-center"
          >
            <b-badge variant="primary" class="mr-1 mb-1">
              <feather-icon icon="HashIcon" class="mr-25" />
              <span>Reserva Foravila Rentals</span>
            </b-badge>

            <b-badge variant="danger" class="mr-1 mb-1">
              <feather-icon icon="HashIcon" class="mr-25" />
              <span>Reserva externa</span>
            </b-badge>

            <b-badge class="mr-1 mb-1">
              <feather-icon icon="HashIcon" class="mr-25" />
              <span>Calendari tancat</span>
            </b-badge>

            <b-badge variant="info" class="mr-1 mb-1">
              <feather-icon icon="HashIcon" class="mr-25" />
              <span>Ús personal</span>
            </b-badge>

            <b-badge variant="warning" class="mr-1 mb-1">
              <feather-icon icon="HashIcon" class="mr-25" />
              <span>No disponible</span>
            </b-badge>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- BOOKING DETAILS MODAL -->
    <b-modal
      id="calendar-booking-details-modal"
      scrollable
      hide-footer
      centered
      title="Detalls de la reserva"
    >
      <booking-details
        :uuid="selectedBookingUuid"
        @close="onBookingDetailsClose"
      />
    </b-modal>

    <!-- DATES LOCK DETAILS MODAL -->
    <b-modal
      id="calendar-dates-lock-details-modal"
      scrollable
      hide-footer
      centered
      title="Detalls del bloqueig"
    >
      <dates-lock-details
        :uuid="selectedDatesLockUuid"
        @close="onDatesLockDetailsClose"
        @dates-lock-deleted="onDatesLockDeleted"
        @delete-dates-lock-error="onDeleteDatesLockError"
      />
    </b-modal>

    <!-- ADD DATES LOCK MODAL -->
    <b-modal
      id="calendar-add-dates-lock-modal"
      scrollable
      hide-footer
      centered
      title="Afegeix un bloqueig"
    >
      <dates-lock-form
        :date="selectedDate"
        :range="selectedRange"
        @cancel="onAddDatesLockCancel"
        @dates-lock-added="onDatesLockAdded"
        @add-dates-lock-error="onAddDatesLockError"
      />
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BModal,
} from "bootstrap-vue";
import { formatDateObjectToDatabaseDate } from "@/utils/formatters";
import Calendar from "v-year-calendar";
import "v-year-calendar/locales/v-year-calendar.ca";
import vSelect from "vue-select";
import { notifyError, notifySuccess } from "@/utils/methods";
import DatesLockForm from "@/views/accommodations/accommodation/components/DatesLockForm.vue";
import DatesLockDetails from "@/views/bookings/calendar/components/DatesLockDetails.vue";
import BookingDetails from "@/views/bookings/calendar/components/BookingDetails.vue";

export default {
  name: "BookingsCalendar",
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    Calendar,
    vSelect,
    BModal,
    DatesLockForm,
    DatesLockDetails,
    BookingDetails,
  },
  data() {
    return {
      selectedYear: new Date().getFullYear(),
      selectedAccommodationUuid: null,
      selectedDate: null,
      selectedRange: null,
      selectedBookingUuid: null,
      selectedDatesLockUuid: null,
      unavailableDates: [],
    };
  },
  computed: {
    loadingAccommodations() {
      return this.$store.getters["accommodations/loading"];
    },
    accommodations() {
      return this.$store.getters["accommodations/accommodations"];
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    accommodationAvailabilities() {
      return this.$store.getters["accommodation/availabilities"];
    },
    accommodationBookings() {
      return this.$store.getters["accommodation/bookings"];
    },
    accommodationDatesLocks() {
      return this.$store.getters["accommodation/datesLocks"];
    },
    activeAccommodationOptions() {
      if (!this.accommodations?.length) return [];
      const activeAccommodations = this.accommodations.filter((a) => a.active);
      return activeAccommodations.map((a) => ({
        label: a.name,
        value: a.uuid,
      }));
    },
  },
  watch: {
    selectedAccommodationUuid(accommodationUuid) {
      this.unavailableDates = [];
      if (accommodationUuid) this.fetchAccommodation();
    },
  },
  created() {
    if (this.accommodations?.length < 10 && !this.loadingAccommodations)
      this.fetchAccommodations();
  },
  methods: {
    fetchAccommodation() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch(
          "accommodation/fetchAccommodation",
          this.selectedAccommodationUuid
        )
        .then(() => this.fetchLockedDates(this.selectedAccommodationUuid))
        .catch(() => {
          notifyError(
            this.$t("errors.fetchAccommodation.title"),
            this.$t("errors.fetchAccommodation.description")
          );
        });
    },
    fetchAccommodations() {
      this.$store
        .dispatch("accommodations/fetchAccommodations", {
          pagination: false,
          sort: "asc",
          groups: "accommodationslist",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchAccommodations.title"),
            this.$t("errors.fetchAccommodations.description")
          )
        );
    },
    fetchLockedDates(accommodationUuid) {
      if (!accommodationUuid) return;

      this.$store.dispatch("app/setLoading", true);

      const promises = [];

      // Availabilities
      promises.push(
        this.$store.dispatch(
          "accommodation/fetchAccommodationAvailabilities",
          accommodationUuid
        )
      );
      // Bookings
      promises.push(
        this.$store.dispatch(
          "accommodation/fetchAccommodationBookings",
          accommodationUuid
        )
      );
      // Dates locks
      promises.push(
        this.$store.dispatch(
          "accommodation/fetchAccommodationDatesLocks",
          accommodationUuid
        )
      );

      Promise.all(promises)
        .then(() => this.setUnavailableDates())
        .catch(() => {
          notifyError(
            "Error",
            "Hi ha hagut un error al intenter carregar les dades"
          );
          this.selectedAccommodationUuid = null;
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onDatesSelected(dates) {
      if (!this.selectedAccommodationUuid) return;

      const startDate = formatDateObjectToDatabaseDate(dates.startDate) || null;
      const endDate = formatDateObjectToDatabaseDate(dates.endDate) || null;
      const rangeSelected = endDate > startDate;

      if (rangeSelected) {
        this.selectedRange = {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          events: dates.events,
        };
        this.selectedDate = null;
      } else {
        this.selectedDate = {
          date: new Date(startDate),
          events: dates.events,
        };
        this.selectedRange = null;
      }

      // Empty date/s selected, open add dates lock modal
      if (!dates.events.length) {
        this.$bvModal.show("calendar-add-dates-lock-modal");
        return;
      }

      // Booking selected, open booking details modal
      const bookingEvents = dates.events.filter((e) => e.type === "booking");
      let bookingsOverlapsDetected = 0;
      bookingEvents.forEach((bookingEvent) => {
        if (!bookingEvent) return;
        if (this.overlapDetected(dates, bookingEvent)) {
          bookingsOverlapsDetected += 1;
        }
      });
      if (bookingsOverlapsDetected > 0) {
        this.selectedBookingUuid = bookingEvents[0].uuid;
        this.$bvModal.show("calendar-booking-details-modal");
        return;
      }

      // Dates lock selected, open dates lock details modal
      const datesLockEvents = dates.events.filter(
        (e) => e.type === "datesLock"
      );
      let datesLocksOverlapsDetected = 0;
      datesLockEvents.forEach((datesLockEvent) => {
        if (!datesLockEvent) return;
        if (this.overlapDetected(dates, datesLockEvent)) {
          datesLocksOverlapsDetected += 1;
        }
      });
      if (datesLocksOverlapsDetected > 0) {
        this.selectedDatesLockUuid = datesLockEvents[0].uuid;
        this.$bvModal.show("calendar-dates-lock-details-modal");
        return;
      }

      // No booking or dates lock selected, open add dates lock modal
      this.$bvModal.show("calendar-add-dates-lock-modal");
    },
    overlapDetected(dates, event) {
      // NOTE: keep in mind that for:
      //    - dates -> startDate: checkin, endDate: checkout
      //    - event -> startDate: start date, endDate: end date (checkout - 1 day)
      // Check if dates intersect
      if (
        dates.startDate.getTime() <= event.endDate.getTime() &&
        event.startDate.getTime() <= dates.endDate.getTime()
      ) {
        // Check if the intersection is on the checkout day, either of the dates or the event
        // return (
        //   dates.endDate.getTime() !== event.startDate.getTime()
        //   || dates.startDate.getTime() === event.startDate.getTime()
        // )
        return true;
      }
      return false;
    },
    onAddButtonClicked() {
      this.selectedDate = null;
      this.selectedRange = null;
      this.$bvModal.show("calendar-add-dates-lock-modal");
    },
    getDatesLockColor(scope) {
      if (!scope) return "black";
      switch (scope.toUpperCase()) {
        case "BOOKING":
          return "#ff5555";
        case "CLOSED":
          return "#9e9e9e";
        case "PERSONAL":
          return "#00cfe8";
        default:
          return "gray";
      }
    },
    setUnavailableDates() {
      const dates = [];
      if (this.selectedAccommodationUuid) {
        // Availabilities
        this.accommodationAvailabilities.forEach((availability) => {
          const startDate = new Date(availability.date);
          startDate.setHours(0, 0, 0, 0);

          const endDate = new Date(availability.date);
          endDate.setHours(0, 0, 0, 0);

          if (!availability.available) {
            dates.push({
              startDate,
              endDate,
              color: "orange",
              type: "availability",
            });
          }
        });
        // Dates locks
        this.accommodationDatesLocks.forEach((datesLock) => {
          const startDate = new Date(datesLock.startDate);
          startDate.setHours(0, 0, 0, 0);

          const endDate = new Date(datesLock.endDate);
          endDate.setHours(0, 0, 0, 0);

          dates.push({
            startDate,
            endDate,
            color: this.getDatesLockColor(datesLock.scope),
            type: "datesLock",
            uuid: datesLock.uuid,
          });
        });
        // Bookings
        this.accommodationBookings.forEach((booking) => {
          if (booking.active) {
            const startDate = new Date(booking.checkin);
            startDate.setHours(0, 0, 0, 0);

            const endDate = new Date(booking.checkout);
            endDate.setHours(0, 0, 0, 0);
            endDate.setDate(endDate.getDate() - 1);

            dates.push({
              startDate,
              endDate,
              color: "#ffcd00",
              type: "booking",
              uuid: booking.uuid,
              localizator: booking.localizator,
            });
          }
        });
      }
      this.unavailableDates = dates;
    },
    onYearChanged() {
      // this.fetchLockedDates(this.selectedAccommodationUuid)
    },
    onBookingDetailsClose() {
      this.$bvModal.hide("calendar-booking-details-modal");
    },
    onDatesLockDetailsClose() {
      this.$bvModal.hide("calendar-dates-lock-details-modal");
    },
    onDatesLockDeleted() {
      this.$bvModal.hide("calendar-dates-lock-details-modal");
      this.fetchLockedDates(this.selectedAccommodationUuid);
      notifySuccess(
        "Bloqueig eliminat",
        "El bolqueig ha estat eliminat correctament"
      );
    },
    onDeleteDatesLockError() {
      // console.error('delete dates lock error', error)
    },
    onAddDatesLockCancel() {
      this.$bvModal.hide("calendar-add-dates-lock-modal");
    },
    onDatesLockAdded() {
      this.fetchLockedDates(this.selectedAccommodationUuid);
      this.$bvModal.hide("calendar-add-dates-lock-modal");
    },
    onAddDatesLockError() {
      // console.error('add dates lock error', error)
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#bookings-calendar {
  .accommodation-select {
    min-width: 250px;
  }
  .calendar {
    .calendar-header {
      margin-bottom: 60px;
    }
    .months-container {
      .month-container {
        height: 225px;
      }
    }
  }
  #calendar-legend {
    .agency-booking {
      background-color: #ffcd00;
      color: white;
    }
    .external-booking {
      background-color: #ff5555;
      color: white;
    }
    .calendar-closed {
      background-color: #9e9e9e;
      color: white;
    }
    .personal-use {
      background-color: #5d8fff;
      color: white;
    }
    .not-available {
      background-color: orange;
      color: white;
    }
  }
}
</style>
