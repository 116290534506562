<template>
  <div id="dates-locks-form">
    <!-- DATES -->
    <b-row v-if="!datesLock" class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Dates</h6>
      </b-col>
      <!-- START DATE -->
      <b-col cols="12" sm="6" class="mb-1 mb-sm-0">
        <b-form-datepicker
          v-model="startDate"
          placeholder="Inici"
          :locale="locale"
          :start-weekday="1"
          :show-decade-nav="false"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          hide-header
        />
      </b-col>
      <!-- END DATE -->
      <b-col cols="12" sm="6">
        <b-form-datepicker
          v-model="endDate"
          placeholder="Fi"
          :min="startDate"
          :locale="locale"
          :start-weekday="1"
          :show-decade-nav="false"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          hide-header
        />
      </b-col>
    </b-row>

    <!-- SCOPE -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6 class="mb-1">Tipus de bloqueig</h6>
        <b-form-radio
          v-model="scope"
          name="bedroom-type-radio"
          value="BOOKING"
          class="mr-2 d-inline"
        >
          Reserva
        </b-form-radio>
        <b-form-radio
          v-model="scope"
          name="bedroom-type-radio"
          value="CLOSED"
          class="mr-2 d-inline"
        >
          Calendari tancat
        </b-form-radio>
        <b-form-radio
          v-model="scope"
          name="bedroom-type-radio"
          value="PERSONAL"
          class="mr-2 d-inline"
        >
          Personal
        </b-form-radio>
      </b-col>
    </b-row>

    <!-- EXPIRY DATE -->
    <b-row class="mb-3">
      <b-col cols="12" class="mb-1">
        <h6>Caducitat <small>(opcional)</small></h6>
      </b-col>
      <b-col cols="12">
        <b-form-datepicker
          v-model="expiryDate"
          placeholder="Data de caducitat del bloqueig"
          :locale="locale"
          :start-weekday="1"
          :show-decade-nav="false"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          hide-header
        />
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col :cols="datesLock ? 4 : 6" sm="auto">
        <b-button block @click="cancel"> Cancel·la </b-button>
      </b-col>
      <b-col v-if="datesLock" cols="4" sm="auto">
        <b-button block variant="danger" @click="confirmDeleteDatesLock">
          Elimina
        </b-button>
      </b-col>
      <b-col :cols="datesLock ? 4 : 6" sm="auto">
        <b-button block variant="primary" @click="saveDatesLock">
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormDatepicker,
  BButton,
  BFormRadio,
} from "bootstrap-vue";

export default {
  name: "DatesLockForm",
  components: {
    BRow,
    BCol,
    BFormDatepicker,
    BButton,
    BFormRadio,
  },
  props: {
    datesLock: {
      type: Object,
      required: false,
      default: null,
    },
    date: {
      type: Object,
      required: false,
      default: null,
    },
    range: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      expiryDate: null,
      scope: "BOOKING",
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    startDate(startDate) {
      if (startDate && (!this.endDate || this.endDate < startDate))
        this.endDate = startDate;
    },
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm() {
      if (this.datesLock) {
        this.startDate = this.datesLock.startDate;
        this.endDate = this.datesLock.endDate;
        this.scope = this.datesLock.scope;
        this.expiryDate = this.datesLock.expiryDate;
        return;
      }
      if (this.date) {
        this.startDate = this.date.date;
        this.endDate = this.date.date;
        return;
      }
      if (this.range) {
        this.startDate = this.range.startDate;
        this.endDate = this.range.endDate;
      }
    },
    resetForm() {
      this.startDate = null;
      this.endDate = null;
      this.scope = null;
      this.expiryDate = null;
    },
    confirmDeleteDatesLock() {
      this.$swal({
        title: "Eliminar bloqueig",
        text: "Estàs a punt d'eliminar aquest bloqueig, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteDatesLock();
      });
    },
    deleteDatesLock() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch(
            "accommodation/deleteAccommodationDatesLock",
            this.datesLock.uuid
          )
          .then(() => this.$emit("dates-lock-deleted"))
          .catch(() => {
            this.$emit("delete-dates-lock-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    createDatesLock() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("accommodation/setAccommodationDatesLock", {
            accommodation: this.accommodation["@id"],
            startDate: this.startDate,
            endDate: this.endDate,
            scope: this.scope,
            expiryDate: this.expiryDate,
          })
          .then(() => this.$emit("dates-lock-added"))
          .catch(() => {
            this.$emit("add-dates-lock-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    editDatesLock() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("accommodation/setAccommodationDatesLock", {
            accommodation: this.accommodation["@id"],
            startDate: this.startDate,
            endDate: this.endDate,
            scope: this.scope,
            expiryDate: this.expiryDate,
          })
          .then(() => this.$emit("dates-lock-edited"))
          .catch(() => {
            this.$emit("edit-dates-lock-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    saveDatesLock() {
      if (this.datesLock) this.editDatesLock();
      else this.createDatesLock();
    },
  },
};
</script>

<style scoped>
#dates-locks-form {
  min-height: 350px;
}
</style>
